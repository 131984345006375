import 'core-js/features/promise';

//@ts-ignore
if (window.fetch) {
  import('./appRender').then(module => module.default());
} else {
  import('./polyfills').then(() => {
    import('./appRender').then(module => module.default());
  });
}
